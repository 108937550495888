<template>
    <v-card height="100%">
			<v-card-title class="mb-0"> 
					Project <b>{{ProjectName}}</b> Progress
			</v-card-title>
			<v-card height="100%" color="#eff4f7" class="pa-5">
        <v-card height="50%" variant="">
          <v-row style="height: 100%;">
            <v-col cols="6" style="height: 100%;">
              <v-card color="white" height="100%">
                <ApexCharts type="area" height="100%" :options="spark.chartOptions" :series="spark.series" :key="spark"></ApexCharts>
              </v-card>
            </v-col>
            <v-col cols="6" style="height: 100%;">
              <v-card  color="white" height="100%">
                <ApexCharts type="radialBar" height="100%" :options="circle.chartOptions" :series="circle.series" :key="circle"></ApexCharts>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card height="50%"  variant="">
          <v-row style="height: 100%;">
            <v-col cols="6" style="height: 100%;">
              <v-card  color="white" height="100%">
                <ApexCharts height="100%" type="donut" :options="pie.chartOptions" :series="pie.series" :key="pie"></ApexCharts>
              </v-card>
            </v-col>
            <v-col cols="6" style="height: 100%;">
              <v-card olor="white" height="100%" width="100%">
                <v-card-title class="mb-0"> 
                    <text style="color: #373d3f; font-family: Helvetica, Arial, sans-serif !important; opacity: 1; font-weight: 900;">Work Hours</text>
                    <v-row class="mt-1 mb-0 py-0">
                        <v-col class="mt-0 mb-0 py-0"><v-select density="compact" label="Year" variant="outlined" v-model="selectedYear" :items="years"></v-select></v-col>
                        <v-col class="mt-0 mb-0 py-0"><v-select density="compact" label="Month" variant="outlined" v-model="selectedMonth" :items="months"></v-select></v-col>
                    </v-row>
                </v-card-title>
                <v-card style="height: calc(100% - 130px);" variant="">
                  <ApexCharts type="bar" height="100%" :options="bar.chartOptions" :series="bar.series" :key="bar"></ApexCharts>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
			</v-card>
    </v-card>
</template>
  
<script>
  import VueApexCharts from "vue3-apexcharts";
  import ProjectService from '../services/ProjectService.js';
  
  export default {
    props: {
      ProjectId: {
        type: Number,
        default: undefined
      },
			ProjectName: {
        type: String,
        default: undefined
      },
    },
    components: {
      ApexCharts: VueApexCharts
    },
    data() {
      return {
        years: [],
        selectedYear: "",
        months: [],
        selectedMonth: "",
        workHours: [],
        circle: {
          series: [],
          chartOptions: {
            chart: {
              height: 350,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                  size: '40%',
                  margin: 5,
                },
                track: {
                  background: '#fff',
                  strokeWidth: '100%',
                  margin: 3,
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#888',
                    fontSize: '17px'
                  },
                  value: {
                    color: '#111',
                    fontSize: '32px',
                    show: true,
                  }
                },
                barLabels: {
                  enabled: true,
                  useSeriesColors: true,
                  fontSize: '16px',
                },
              }
            },
          stroke: {
            lineCap: 'round'
          },
          colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
          labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn'],
          title: {
            text: "Project Status",
            offsetX: 0,
            style: {
              fontSize: '24px',
            }
          },
          subtitle: {
            text: 'Activities Progress',
            offsetX: 0,
            style: {
              fontSize: '14px',
            }
          }
        },
        },
        pie: {
          series: [],
          chartOptions: {
            labels: [],
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return Math.round((val / 100) * 0)  + "h"
              },
            },
            chart: {
              type: 'donut',
              width: 100,
            },
            title: {
              text: "Work Distribution",
              offsetX: 0,
              style: {
                fontSize: '24px',
              }
            },
            subtitle: {
              text: 'Hours',
              offsetX: 0,
              style: {
                fontSize: '14px',
              }
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      label: "Total",
                      show: true,
                      showAlways: true,
                    },
                  }
                }
              }
            }
          },
        },
        spark: {
          series: [],
          chartOptions: {
            colors:['#008ffb', '#B00020'], 
            chart: {
              type: 'area',
              sparkline: {
                enabled: false
              },
              zoom: {
                enabled: true
              },
              toolbar: {
                show: true
              },
            },
            stroke: {
              curve: 'straight'
            },
            fill: {
              opacity: 0.3
            },
            xaxis: {
              type: 'datetime',
            },
            yaxis: {
              opposite: true
            },
            title: {
              text: '€0,0',
              offsetX: 0,
              style: {
                fontSize: '24px',
              }
            },
            subtitle: {
              text: 'Revenue',
              offsetX: 0,
              style: {
                fontSize: '14px',
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function(value) {
                return value + "€"
              }
            },
            tooltip:{
              y: {
                formatter: function(value) {
                  return value + "€"
                }
              }
            }
          }
        },
        bar: {
          chartOptions: {
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
              },
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900
                    }
                  }
                }
              },
            },
            xaxis: {
              type: 'string',
              categories: [],
              tickPlacement: 'on',
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
          },
          series: [{
            name: 'Billable',
            data: []
          }, {
            name: 'Not Billable',
            data: []
          }],
        },
        splitCircle: {},
        totalCircle: {}
      };
    },
    methods: {
      reloadGraph(){
        ProjectService.getStatus(this.ProjectId).then((circleData) => {
          if(circleData.length == 0 || circleData[0].TimeBudget == null)
            return;
          circleData = circleData.map((el) => {
            const [hours, minutes, seconds] = el.ActualHours.split(':').map(Number);
            return {
              TimeBudget: el.TimeBudget,
              ActualHours: hours + (minutes / 60) + (seconds / 3600),
              Name: el.Name,
              Percentage: ((hours + (minutes / 60) + (seconds / 3600)) / (el.TimeBudget != 0 ? el.TimeBudget : 1)) * 100
            };
          })
          let totalCircleData = circleData.map((el) => {
            return {
              TimeBudget: el.TimeBudget,
              ActualHours: el.ActualHours
            };
          }).reduce((acc, el) => {
            acc.TimeBudget += el.TimeBudget;
            acc.ActualHours += el.ActualHours;
            return acc;
          }, { TimeBudget: 0, ActualHours: 0 });
          totalCircleData.Percentage = (totalCircleData.ActualHours / (totalCircleData.TimeBudget != 0 ? totalCircleData.TimeBudget : 1)) * 100;
          this.circle.series = [totalCircleData.Percentage];
          this.circleDataSeries = [totalCircleData];
          this.circle.chartOptions.labels = ["Total"];
          this.circle.chartOptions.colors = [totalCircleData.ActualHours > totalCircleData.TimeBudget ? "#B00020" : "#1867c0"];
          circleData.forEach((el) => {
            this.circle.series.push(el.Percentage);
            this.circle.chartOptions.labels.push(el.Name)
            this.circleDataSeries.push(el);
            this.circle.chartOptions.colors.push(el.ActualHours > el.TimeBudget ? "#B00020" : this.getRandomPastelColor())
          })
          this.circle = JSON.parse(JSON.stringify(this.circle));
          this.circle.chartOptions.plotOptions.radialBar.dataLabels.value.formatter = (value) => {
            value;
            return Number(value).toFixed(0) + "%";
          };
          this.circle.chartOptions.plotOptions.radialBar.barLabels.formatter = (seriesName, opts) => {
            return seriesName + ":  " + this.circleDataSeries[opts.seriesIndex].ActualHours + " / " + this.circleDataSeries[opts.seriesIndex].TimeBudget + "h" + " ";
          };
        });
        ProjectService.getStatusByUsers(this.ProjectId).then((pieData) => {
          let totalHours = pieData.reduce((acc, el) => {
            const [hours, minutes, seconds] = el.WorkedHours.split(':').map(Number);
            return acc + (hours + (minutes / 60) + (seconds / 3600))
          }, 0);
          if (totalHours == 0){
            this.pie.chartOptions.plotOptions.pie.donut.labels.total.label = "No Data";
          }
          let pieSeries = pieData.map((el) => {
            const [hours, minutes, seconds] = el.WorkedHours.split(':').map(Number);
            return hours + (minutes / 60) + (seconds / 3600) 
          }); 
          this.pie.series = pieSeries;
          this.pie.chartOptions.labels = pieData.map((el) => el.Username);
          this.pie = JSON.parse(JSON.stringify(this.pie));
          this.pie.chartOptions.dataLabels.formatter = (val) => {
            return Math.round((val / 100) * totalHours)  + "h";
          };
          if(totalHours == 0){
            this.pie.chartOptions.plotOptions.pie.donut.labels.total.formatter = () => {
              return "";  
            };
          }
          else{
            this.pie.chartOptions.plotOptions.pie.donut.labels.total.formatter = () => {
              return totalHours + "h";  
            };
          }
        });
        ProjectService.getStatusRevenue(this.ProjectId).then((sparkData) => {
          if (sparkData.length > 0 && sparkData[0].TimeBudget != null){
            let totalHours = sparkData.reduce((acc, el) => {
            const [hours, minutes, seconds] = el.WorkHours.split(':').map(Number);
            return acc + (hours + (minutes / 60) + (seconds / 3600));
          }, 0);

          let budgetHours = sparkData[0].TimeBudget;
          let hourlyRate = sparkData[0].HourlyRate;
          let earnings = totalHours * hourlyRate;
          let loss = (totalHours - budgetHours) * hourlyRate;
          loss = loss > 0 ? loss : 0;
          earnings = earnings - loss;

          this.spark.chartOptions.title.text = loss > 0 ? "€" + earnings + " / €" + loss : "€" + earnings;
          this.spark.chartOptions.subtitle.text = loss > 0 ? "Revenue / Loss" : "Revenue";

          // Sort the data by date
          sparkData.sort((a, b) => new Date(a.Date) - new Date(b.Date));

          let accumulatedHours = 0;
          let series0Data = [];
          let series1Data = [];

          sparkData.forEach(el => {
            const [hours, minutes, seconds] = el.WorkHours.split(':').map(Number);
            const workHours = hours + (minutes / 60) + (seconds / 3600);
            const workEarnings = workHours * hourlyRate;

            accumulatedHours += workHours;

            if (accumulatedHours <= budgetHours) {
              series0Data.push([new Date(el.Date), workEarnings]);
            } else if (accumulatedHours - workHours < budgetHours) {
              const remainingBudgetHours = budgetHours - (accumulatedHours - workHours);
              const series0Earnings = remainingBudgetHours * hourlyRate;
              const series1Hours = workHours - remainingBudgetHours;
              const series1Earnings = series1Hours * hourlyRate;

              series0Data.push([new Date(el.Date), series0Earnings]);
              series1Data.push([new Date(el.Date), series1Earnings]);
            } else {
              series1Data.push([new Date(el.Date), workEarnings]);
            }
          });
          this.spark.series = [];
          this.spark.series.push({data: series0Data, name: "Revenue"});
          if (loss > 0){
            this.spark.series.push({data: series1Data, name: "Loss"});
          }

          this.spark.chartOptions = JSON.parse(JSON.stringify(this.spark.chartOptions));
          this.spark.series = JSON.parse(JSON.stringify(this.spark.series));

          }
          
          //this.spark.chartOptions.title = totalEarnings;
        })
        ProjectService.getStatusHoursFilters(this.ProjectId).then((filtersData) => {
          if (filtersData.length == 0)
            return;
          filtersData.forEach((el) => {
            if(!this.years.includes(el.Year))
              this.years.push(el.Year);
            if(!this.months.includes(el.Month))
              this.months.push(el.Month);
          })
          if(this.months.length > 0){
            this.selectedMonth = this.months[0];
            this.selectedYear = this.years[0];
            this.reloadBar();
          }
        })
      },
      reloadBar(){
        if (this.selectedMonth != "" && this.selectedYear != ""){
          ProjectService.getStatusHours(this.ProjectId, Number(this.selectedYear), Number(this.selectedMonth)).then(barData => {
            this.bar.series = [];
            this.bar.chartOptions.xaxis.categories = [];

            this.bar.series = barData.reduce((acc, item) => {
              const { Username, WorkHours } = item;
              let user = acc.find(user => user.name === Username);
              if (!user) {
                user = { name: Username, data: [] };
                acc.push(user);
              }
              user.data.push(WorkHours);

              return acc;
            }, []);

            this.bar.series[0].data.forEach((el, index) => {
              el;
              this.bar.chartOptions.xaxis.categories.push(index+1);
            })

            this.bar = JSON.parse(JSON.stringify(this.bar));
          });
        }
      },
      getRandomPastelColor() {
          let g = Math.floor(Math.random() * 128 + 127);
          let b = Math.floor(Math.random() * 128 + 127);
          let r = 0;
          let rHex = r.toString(16).padStart(2, '0');
          let gHex = g.toString(16).padStart(2, '0');
          let bHex = b.toString(16).padStart(2, '0');
          return `#${rHex}${gHex}${bHex}`;
      }
    }, 
    computed:{
    },
    mounted(){
      this.reloadGraph();
    },
    watch: {
      selectedMonth() {
        this.reloadBar();
      },
      selectedYear() {
        this.reloadBar();
      },
    },
  };
</script>
