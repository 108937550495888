<template>
  <v-card width="100%" height="100%">
    <v-row class="mt-5">
      <v-spacer></v-spacer>
      <v-col>
        <v-card width="100%">
          <v-tabs
            v-model="tab"
            align-tabs="center"
            bg-color="primary"
            show-arrows
            >
            <v-btn variant="plain" icon="mdi-arrow-left" @click="previousTab()" color="white"></v-btn> 
            <v-tab :value="0">Mon<br>{{ week[0].getDate() }}</v-tab>
            <v-tab :value="1">Tue<br>{{ week[1].getDate() }}</v-tab>
            <v-tab :value="2">Wed<br>{{ week[2].getDate() }}</v-tab>
            <v-tab :value="3">Thu<br>{{ week[3].getDate() }}</v-tab>
            <v-tab :value="4">Fry<br>{{ week[4].getDate() }}</v-tab>
            <v-tab :value="5">Sat<br>{{ week[5].getDate() }}</v-tab>
            <v-tab :value="6">Sun<br>{{ week[6].getDate() }}</v-tab>
            <v-btn variant="plain" icon="mdi-arrow-right" @click="nextTab()" color="white"></v-btn> 
            <v-btn variant="plain" icon="mdi-calendar-month" @click="openCalendar()" color="white"></v-btn> 
          </v-tabs>
          <v-dialog v-model="datePicker" persistent max-width="360"
            transition="dialog-bottom-transition">
            <v-date-picker v-model="datePickerValue" color="primary" @update:model-value="datePickerChanged()"></v-date-picker>
          </v-dialog>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="11" sm="8" md="7" lg="5" xl="4">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="openAddDialog()"><v-icon>mdi-plus</v-icon> Add Time Entry</v-btn>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-for="entry in entries" :key="entry.id">
          <v-card  class="mt-2" width="100%" bg-color="blue-grey-lighten-5" :title="`${entry.ClientName} / ${entry.ProjectName} / ${entry.ActivityName}`">
            <template v-slot:append>
              <v-btn size="small" class="mx-1 mb-1 mt-0" :color="entry.TimerStart != null ? 'primary' : ''"  @click="manageTimer(entry)">
                <v-progress-circular v-if="entry.TimerStart != null" color="white" :size="20" class="mr-2" indeterminate ></v-progress-circular>{{entry.Time}}
              </v-btn>
              <v-btn v-if="entry.IsEnabled" size="small" icon class="mx-1 mb-1 mt-0"  variant="text" @click="openEditDialog(entry)"><v-icon>mdi-pencil</v-icon></v-btn>
              <v-btn v-if="entry.IsEnabled" size="small" icon class="mx-1 mb-1 mt-0"  variant="text" @click="deleteEntry(entry)"><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn v-if="!entry.IsEnabled" size="small" icon class="mx-1 mb-1 mt-0"  variant="text" ><v-icon>mdi-lock</v-icon></v-btn>
            </template>
              <v-card-text>{{entry.Description}}</v-card-text>
          </v-card>
        </v-row>
        <v-dialog v-model="edit" persistent max-width="600px" transition="dialog-bottom-transition">
          <v-alert color="error" icon="$error" title="Error" :text="errorEdit.text" v-if="errorEdit.status" ></v-alert>
          <entry-form
            title="Edit Entry"
            :projectList="projectList"
            :activityList="activityList"
            :currentEntry="currentEntry"
            @cancel="cancelEdit"
            @save="saveEdit"
            @update:entry="updateCurrentEntry"
          ></entry-form>
        </v-dialog>
        <v-dialog v-model="add" persistent max-width="600px" transition="dialog-bottom-transition">
          <v-alert color="error" icon="$error" title="Error" :text="errorAdd.text" v-if="errorAdd.status" ></v-alert>
          <entry-form
            title="Add Entry"
            :projectList="projectList"
            :activityList="activityList"
            :currentEntry="newEntry"
            @cancel="cancelAdd"
            @save="saveAdd"
            @update:entry="updateNewEntry"
          ></entry-form>
        </v-dialog>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import AuthService from '../services/AuthService.js';
import UserService from '../services/UserService.js';
import EntryService from '../services/EntryService.js';
import ProjectService from '../services/ProjectService.js';
import { globalContainer } from '../store';
import router from "../router"
import EntryForm from '../components/EntryForm.vue';

export default defineComponent({
  components: { EntryForm },
  name: 'TimeTrackingView',
  data: () => ({
    week: [new Date(),new Date(),new Date(),new Date(),new Date(),new Date(),new Date()],
    items: [],
    tab: null,
    datePicker: false,
    datePickerValue: new Date(),
    edit: false,
    add: false,
    currentEntry: {},
    newEntry: {},
    entries: [],
    projectList: [],
    activityList: [],
    errorEdit: {text: "", status: false},
    errorAdd: {text: "", status: false},
  }),
  methods: {
    loginFailed() {
      this.$cookies.remove('username');
      this.$cookies.remove('password');
      this.$cookies.remove('current_user');
      router.push("/login");
    },
    nextTab(){
      if (this.tab + 1 > 6){
        //change week
        let date = new Date(this.week[this.tab]);
        date.setDate(this.week[this.tab].getDate() + 1);
        this.loadWeek(date);
        this.tab = 0;
      }
      else {
        this.tab += 1;
      }
    },
    previousTab(){
      if (this.tab - 1 < 0){
        //change week
        let date = new Date(this.week[this.tab]);
        date.setDate(this.week[this.tab].getDate() - 1);
        this.loadWeek(date, false);
        this.tab = 6;
      }
      else {
        this.tab -= 1;
      }
    },
    openCalendar(){
      this.datePicker = true;
    },
    datePickerChanged(){
      this.week.forEach((day, index) => {
        if (day.toISOString() == this.datePickerValue.toISOString()){
          this.tab = index;
        }
      });
      if(this.week.filter((day) => day.toISOString() == this.datePickerValue.toISOString()) == 0){
        //if date is not inside week, load other week
        this.loadWeek(this.datePickerValue);
        this.changedTab();
      }
      this.reloadEntries();
      this.datePicker = false;
    },
    changedTab(){
      let day = this.week[this.tab];
      const offset = day.getTimezoneOffset();
      day = new Date(day.getTime() - (offset*60*1000));
      router.replace({query: {date: day.toISOString().split('T')[0]}});
      this.datePickerValue = this.week[this.tab];
    },
    loadWeek(currentDate, rollback){
      const currentDayOfWeek = currentDate.getDay();
      const startOfWeek = new Date(currentDate);
      if (rollback != undefined){
        startOfWeek.setDate(currentDate.getDate() - (currentDayOfWeek+6));
      }
      else{
        startOfWeek.setDate(currentDate.getDate() - (currentDayOfWeek-1));
      }
      this.week = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        day.setHours(0,0,0,0);
        this.week.push(day);
        if(currentDate.toISOString() == day.toISOString()){
          this.tab = i;
          this.datePickerValue = currentDate;
        }
      }
    },
    manageTimer(entry){
      //TODO manage timer of entry
      if (entry.TimerStart == null){
        EntryService.editEntry(entry.Id, entry.IdActivity, entry.Time, entry.Description, new Date(entry.Date), new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))).then(() => {
          this.reloadEntries(() => {
          });
        })
      }
      else{
        let offsetMilliseconds = entry.StartingTime.split(':').reduce((acc, time) => 60 * acc + +time) * 60000;
        entry.Time = new Date(Math.abs(new Date(entry.TimerStart) - new Date()) + offsetMilliseconds).toISOString().substring(11, 16);
        EntryService.editEntry(entry.Id, entry.IdActivity, entry.Time, entry.Description, new Date(entry.Date), null).then(() => {
          this.reloadEntries(() => {
          });
        })
      }
    },
    openEditDialog(entry){
      this.currentEntry = JSON.parse(JSON.stringify(entry));
      this.edit = true;
    },
    updateCurrentEntry(entry) {
      this.currentEntry = entry;
    },
    cancelEdit(){
      this.edit = false;
    },
    saveEdit(entry){
      EntryService.editEntry(entry.Id, entry.IdActivity, entry.Time, entry.Description, new Date(entry.Date)).then(() => {
        this.reloadEntries(() => {
          this.edit = false;
        });
      }).catch((error) => {
        this.errorEdit.status = true;
        this.errorEdit.text = error.response.data;
        setTimeout(() => {
          this.errorEdit.status = false;
        }, (5000));
      });
    },
    openAddDialog(){
      this.newEntry = JSON.parse(JSON.stringify({Id: null, IdProject: null, IdActivity: null, Description: "", Time: "00:00"}));
      this.add = true;
    },
    cancelAdd(){
      this.add = false;
    },
    saveAdd(entry){
      EntryService.addEntry(entry.IdActivity, entry.Time , entry.Description, this.week[this.tab]).then(() => {
        this.reloadEntries(() => {
          this.add = false;
        });
      }).catch((error) => {
        this.errorAdd.status = true;
        this.errorAdd.text = error.response.data;
        setTimeout(() => {
          this.errorAdd.status = false;
        }, (5000));
      });
    },
    updateNewEntry(entry) {
      this.newEntry = entry;
    },
    deleteEntry(entry){
      EntryService.deleteEntry(entry.Id).then(() =>{
        this.reloadEntries(() => {})
      })
    },
    reloadEntries(func){
      EntryService.getEntries(this.week[this.tab]).then(result => {
      result.map(el => {
        el.Date = new Date(el.Date);
        el.StartingTime = el.Time+"";
        return el
      })
      this.entries = result;
      this.entries.forEach((el) => {
        if (el.TimerStart != null){
          let offsetMilliseconds = el.StartingTime.split(':').reduce((acc, time) => 60 * acc + +time) * 60000;
          el.Time = new Date(Math.abs(new Date(el.TimerStart) - new Date()) + offsetMilliseconds).toISOString().substring(11, 16);
        }
      })
      func();
    }).catch(() => {this.entries = []});
    },
    refreshTimer(){
      this.entries.forEach((el) => {
        if (el.TimerStart != null){
          let offsetMilliseconds = el.StartingTime.split(':').reduce((acc, time) => 60 * acc + +time) * 60000;
          el.Time = new Date(Math.abs(new Date(el.TimerStart) - new Date()) + offsetMilliseconds).toISOString().substring(11, 16);
        }
      })
      setInterval(() => {
        this.refreshTimer();
      }, 15000)
    }
  },
  beforeMount() {
    AuthService.getUser()
    .then((data) => {
      globalContainer.User = data.Login;
      UserService.getUsers().then(data => {
        this.items = data;
      })
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          router.go();
        })
        .catch(() => {
          this.loginFailed();
        })
      }
      else{
        globalContainer.User = {};
        this.loginFailed();
      }
    });
  },
  mounted(){
    // populate list of dates
    let currentDate = new Date(this.$route.query.date || new Date());
    currentDate.setHours(0,0,0,0);
    this.loadWeek(currentDate);
    this.reloadEntries(() => {});
    ProjectService.getProjects().then(result => {
      result.map((p) => {
        p.Name = `${p.Name} (${p.ClientName})`;
        return p;
      })
      this.projectList = result;
    }).catch(() => {});
    this.refreshTimer();
  },
  watch: {
    tab() {
      this.changedTab();
      this.reloadEntries(() => {});
    },
  },
});
</script>
