import axios from 'axios';

class EntryService {

    static getProjects() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getProjectsByUser(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project?idUser=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getUsersByProject(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project/users?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static addUserToProject(idProject, idUser) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/assignments`, {idUser: idUser, idProject: idProject}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static removeUserFromProject(idProject, idUser) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/assignments?idUser=${idUser}&idProject=${idProject}`,{ withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getProjectsByClient(idClient) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project?idClient=${idClient}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static addProject(idClient, name, description, hourlyRate) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/project`, {idClient: idClient, name: name, description: description, hourlyRate: hourlyRate}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static editProject(id, name, description, hourlyRate) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/api/project`, {id: id, name: name, description: description, hourlyRate: hourlyRate}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static deleteProject(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/project?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getStatus(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project/status?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getStatusByUsers(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project/status/users?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getStatusRevenue(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project/status/revenue?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getStatusHours(id, year, month) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project/status/hours?id=${id}&year=${year}&month=${month}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getStatusHoursFilters(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/project/status/hours/filters?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default EntryService;
