import axios from 'axios';

class ClientService {

    static getClients() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/client`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static addClient(name) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/client`, { name:name, }, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static editClient(name, id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/api/client`, { name:name, id:id}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static disableClient(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/client?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default ClientService;
