<template>
    <v-card width="100%" height="100%" class="text-center">
      <v-card-title>Manage Confirmations</v-card-title>
      <v-row class="mt-2">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="12" md="10" lg="8" xl="8">
          <v-card class="text-left">
            <v-card-title>
                <v-row class="mt-1 mb-0 py-0">
                    <v-col class="mt-0 mb-0 py-0"><v-select density="compact" label="Year" variant="outlined" v-model="selectedYear" :items="years"></v-select></v-col>
                    <v-col class="mt-0 mb-0 py-0"><v-select density="compact" label="Month" variant="outlined" v-model="selectedMonth" :items="months"></v-select></v-col>
                </v-row>
            </v-card-title>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(project, index) in confirmations" :active="index === selectedClient" :key="index">
              <v-expansion-panel-title v-slot="{ }">
                <v-row no-gutters>
                  <v-col class="d-flex justify-start" cols="10">
                    {{project.Name}}
                  </v-col>
                  <v-col
                    class="text--secondary"
                    cols="2"
                  >
                    <v-chip>
                      {{ project.Confirmations.filter(el => el.IsConfirmed).length }} / {{ project.Confirmations.length }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-data-table :items="project.Confirmations" :headers="headers" height="100%">
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.working="{ item }">
                  <v-chip :color="Boolean(item.IsWorkingOnProject) ? 'primary' : 'error'">{{ Boolean(item.IsWorkingOnProject) ? "Yes" : "No" }}</v-chip>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.confirmation="{ item }">
                  <v-chip :disabled="!item.IsWorkingOnProject" :color="Boolean(item.IsConfirmed) ? 'primary' : 'error'">{{ Boolean(item.IsConfirmed) ? "Confirmed" : "Not Confirmed" }}</v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  // Components
  import AuthService from '../services/AuthService.js';
  import { globalContainer } from '../store';
  import router from "../router"
  import ConfirmationService from '../services/ConfirmationsService.js';
  
  export default defineComponent({
    name: 'ManageConfrimationsView',
    data: () => ({
      confirmations: [],
      years: [],
      months: [],
      selectedMonth: "",
      selectedYear: "",
      headers: [
        { title: 'Username', value: 'Username' },
        { title: 'Working On Project', value: 'working', sortable: false },
        { title: 'Status', value: 'confirmation', sortable: false },
      ],
    }),
    methods: {
      loginFailed() {
        this.$cookies.remove('username');
        this.$cookies.remove('password');
        this.$cookies.remove('current_user');
        router.push("/login");
      },
      reloadTable(){
        ConfirmationService.getConfirmations(this.selectedMonth, this.selectedYear).then(result => {
            let refactoredData = [];
            result.forEach(item => {
              let project = refactoredData.find(proj => proj.Name === item.Name);
              if (!project) {
                project = {
                  Name: item.Name,
                  Confirmations: []
                };
                refactoredData.push(project);
              }
              project.Confirmations.push({
                IdUser: item.IdUser,
                Username: item.Username,
                IsConfirmed: item.IsConfirmed,
                IsWorkingOnProject: item.IsWorkingOnProject
              });
            });
            this.confirmations = refactoredData;
        });
      },
    },  
    beforeMount() {
      AuthService.getUser()
      .then((data) => {
        globalContainer.User = data.Login;
        if(globalContainer.User.IsAdmin == false){
          router.push("/");
        }
      })
      .catch(() => {
        if(this.$cookies.get("username") != undefined){
          AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
            router.go();
          })
          .catch(() => {
            this.loginFailed();
          })
        }
        else{
          globalContainer.User = {};
          this.loginFailed();
        }
      });
    },
    computed: {
    },
    watch:{
      selectedMonth(){
        this.reloadTable();
      },
      selectedYear(){
        this.reloadTable();
      }
    },
    mounted(){
        ConfirmationService.getConfirmationsFilters().then((result) => {
            result.forEach((el) => {
                if(!this.years.includes(el.Year))
                this.years.push(el.Year);
                if(!this.months.includes(el.Month))
                this.months.push(el.Month);
            })
            if(this.months.length > 0){
                this.selectedMonth = this.months[0];
                this.selectedYear = this.years[0];
                this.reloadTable();
            }
        });
    }
  });
  </script>
  
  <style>
  .search-field-with-icon .v-icon {
    background-color: #e2ecf7; /* Light grey background */
    border-radius: 50%; /* Circular shape */
    padding: 25px; /* Smaller touch area */
    margin-right: -12px; /* Adjust based on your layout */
    cursor: pointer; /* Cursor changes to pointer on hover */
    color: #1f6cc1;
  }
  /* Adjust icon color on hover */
  .search-field-with-icon .v-icon:hover {
    background-color: #bfd9f5; /* Darker grey on hover */
  }
  </style>