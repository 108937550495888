import axios from 'axios';

class ConfirmationService {

    static getConfirmations(month, year, id=undefined) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/confirmations?month=${month}&year=${year}&id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static editConfirmations(isConfirmed, isWorkingOnProject, id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/api/confirmations`, { isConfirmed:isConfirmed, isWorkingOnProject:isWorkingOnProject, id:id}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getConfirmationsFilters() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/confirmations/filters`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default ConfirmationService;
