import axios from 'axios';

class ActivityService {

    static getActivities(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/activity?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static addActivity(name, idProject, timeBudget) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/activity`, { name:name, idProject: idProject, timeBudget: timeBudget}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static editActivity(name, idProject, id, timeBudget) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/api/activity`, { name:name, idProject: idProject, id:id, timeBudget:timeBudget}, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static deleteActivity(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/activity?id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default ActivityService;
