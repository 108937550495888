<template>
  <v-card>
    <v-card-title>{{title}} </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-autocomplete
            variant="outlined"
            label="Project"
            :items="projectList"
            v-model="localEntry.IdProject"
            item-title="Name"
            item-value="Id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-autocomplete
            variant="outlined"
            label="Activity"
            :items="activityList"
            v-model="localEntry.IdActivity"
            item-title="Name"
            item-value="Id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-text-field
        label="Time"
        v-model="localEntry.Time"
        @change="formatTime"
        variant="outlined"
      ></v-text-field>
      <v-textarea
        label="Description"
        v-model="localEntry.Description"
        variant="outlined"
      ></v-textarea>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="cancel" color="primary" variant="text">Cancel</v-btn>
      <v-btn @click="save" color="primary" variant="tonal">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ActivityService from '../services/ActivityService.js';
// TODO ADD FORM RULES
export default {
  props: {
    projectList: Array,
    currentEntry: Object,
    title: String,
  },
  data() {
    return {
      localEntry: JSON.parse(JSON.stringify(this.currentEntry)),
      activityList: []
    };
  },
  methods: {
    formatTime(event) {
      this.localEntry.Time = this.applyTimeFormat(event.target.value);
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.$emit('save', this.localEntry);
    },
    applyTimeFormat(value) {
      let formatted = value.replace(/[^0-9]:/g, ''); 
      if(!formatted.includes(":")){
        formatted+=":00"
      }
      if(formatted.split(":")[formatted.split(":").length-1] == ""){
        formatted+="00"
      }
      if (formatted.length == 0){
        formatted = "00:00";
      }
      return formatted;
    },
  },
  mounted() {
    ActivityService.getActivities(this.localEntry.IdProject).then((result) => {
      this.activityList = result;
    })
  },
  watch: {
    currentEntry: {
      deep: true,
      handler(newValue) {
        this.localEntry = JSON.parse(JSON.stringify(newValue));
      },
    },
    'currentEntry.Time'(newValue) {
      this.localEntry.Time = this.applyTimeFormat(newValue);
    },
    'localEntry.IdProject'() {
      ActivityService.getActivities(this.localEntry.IdProject).then((result) => {
        this.activityList = result;
        if (result.length > 0){
          this.localEntry.IdActivity = result[0].Id;
        }
      })
    },
  },
};
</script>
