<template>
    <v-card width="100%" height="100%" class="text-center">
      <v-card-title>Confirmations</v-card-title>
      <v-row class="mt-2">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="12" md="10" lg="8" xl="8">
          <v-card class="text-left" min-height="70vh">
            <v-card-title>
                <v-row class="mt-1 mb-0 py-0">
                    <v-col class="mt-0 mb-0 py-0"><v-select density="compact" label="Year" variant="outlined" v-model="selectedYear" :items="years"></v-select></v-col>
                    <v-col class="mt-0 mb-0 py-0"><v-select density="compact" label="Month" variant="outlined" v-model="selectedMonth" :items="months"></v-select></v-col>
                </v-row>
            </v-card-title>
            <v-data-table :items="confirmations" :headers="headers" height="100%">
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.working="{ item }">
              <v-switch label="Working On Project" color="primary" :model-value="Boolean(item.IsWorkingOnProject)" @click="workingToggle(item)"></v-switch>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.confirmation="{ item }">
                <v-switch :disabled="!item.IsWorkingOnProject" label="Confirmed" color="primary" :model-value="Boolean(item.IsConfirmed)" @click="confirmToggle(item)"></v-switch>
            </template>
          </v-data-table>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  // Components
  import AuthService from '../services/AuthService.js';
  import { globalContainer } from '../store';
  import router from "../router"
  import ConfirmationService from '../services/ConfirmationsService.js';
  
  export default defineComponent({
    name: 'ConfrimationsView',
    data: () => ({
      confirmations: [],
      years: [],
      months: [],
      selectedMonth: "",
      selectedYear: "",
      headers: [
        { title: 'Project', value: 'Name' },
        { title: 'Are you working on this project?', value: 'working', sortable: false },
        { title: 'Status', value: 'confirmation', sortable: false },
      ],
    }),
    methods: {
      loginFailed() {
        this.$cookies.remove('username');
        this.$cookies.remove('password');
        this.$cookies.remove('current_user');
        router.push("/login");
      },
      reloadTable(){
        ConfirmationService.getConfirmations(this.selectedMonth, this.selectedYear, globalContainer.User.Id).then(result => {
            this.confirmations = result;
        });
      },
      confirmToggle(item){
        ConfirmationService.editConfirmations(!item.IsConfirmed, item.IsWorkingOnProject, item.Id).then(() => {
            this.reloadTable();
        })
      },
      workingToggle(item){
        item.IsWorkingOnProject = !item.IsWorkingOnProject;
        if(item.IsWorkingOnProject == false){
            item.IsConfirmed = false;
        }
        ConfirmationService.editConfirmations(item.IsConfirmed, item.IsWorkingOnProject, item.Id).then(() => {
            this.reloadTable();
        })
      }
    },  
    beforeMount() {
      AuthService.getUser()
      .then((data) => {
        globalContainer.User = data.Login;
      })
      .catch(() => {
        if(this.$cookies.get("username") != undefined){
          AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
            router.go();
          })
          .catch(() => {
            this.loginFailed();
          })
        }
        else{
          globalContainer.User = {};
          this.loginFailed();
        }
      });
    },
    computed: {
    },
    watch:{
      selectedMonth(){
        this.reloadTable();
      },
      selectedYear(){
        this.reloadTable();
      }
    },
    mounted(){
        ConfirmationService.getConfirmationsFilters().then((result) => {
            result.forEach((el) => {
                if(!this.years.includes(el.Year))
                this.years.push(el.Year);
                if(!this.months.includes(el.Month))
                this.months.push(el.Month);
            })
            if(this.months.length > 0){
                this.selectedMonth = this.months[0];
                this.selectedYear = this.years[0];
                this.reloadTable();
            }
        });
    }
  });
  </script>
  
  <style>
  .search-field-with-icon .v-icon {
    background-color: #e2ecf7; /* Light grey background */
    border-radius: 50%; /* Circular shape */
    padding: 25px; /* Smaller touch area */
    margin-right: -12px; /* Adjust based on your layout */
    cursor: pointer; /* Cursor changes to pointer on hover */
    color: #1f6cc1;
  }
  /* Adjust icon color on hover */
  .search-field-with-icon .v-icon:hover {
    background-color: #bfd9f5; /* Darker grey on hover */
  }
  </style>