import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import TimeTrackingView from '../views/TimeTrackingView.vue'
import ProfileView from '../views/ProfileView.vue'
import UsersView from '../views/UsersView.vue'
import ClientProjectView from '../views/ClientProjectView.vue'
import ConfirmationView from '../views/ConfirmationView'
import ManageConfirmationsView from '../views/ManageConfirmationsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  },{
    path: '/projects',
    name: 'projects',
    component: ClientProjectView
  },
  {
    path: '/timetracking',
    name: 'timetracking',
    component: TimeTrackingView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: "/confirmation",
    name: "confirmation",
    component: ConfirmationView
  },
  {
    path: "/manageconfirmations",
    name: "manageconfirmations",
    component: ManageConfirmationsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
