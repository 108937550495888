<template>
  <v-card width="100%" height="100%" class="text-center">
    <v-card-title>Users Management</v-card-title>
    <v-row class="mt-2">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12" md="10" lg="8" xl="8">
        <v-card class="text-left" min-height="70vh">
          <v-card-title>
            <v-text-field v-model="search"  label="Search..." variant="outlined" append-icon="mdi-plus" @click:append="addUserDialog()" class="search-field-with-icon"></v-text-field>
          </v-card-title>
          <v-data-table :items="getItems" :headers="headers" height="100%" :search="search">
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip location="top" text="Delete">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon variant="" size="small"  class="mr-2" @click="deleteUserDialog(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip location="top" text="Edit">
              <template v-slot:activator="{ props }">
                <v-btn  v-bind="props" icon variant="" size="small" @click="editUserDialog(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip location="top" text="Show Workhours Graph">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon variant="" size="small" @click="showUserDashboard(item)">
                  <v-icon>mdi-chart-line</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip location="top" text="Show Time Entries">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon variant="" size="small" @click="showEntriesDialog(item)">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-dialog v-model="edit" persistent max-width="800px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          Editing User <b>{{ editUser.Username }}</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-checkbox v-model="editUser.IsAdmin" label="Is Admin?"></v-checkbox>
              <v-checkbox v-model="editUser.IsTimeTracking" label="Is Time Tracking?"></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row >
                <v-card color="primary" width="100%" height="40vh">
                  <v-card-title>Assigned Projects</v-card-title>  
                  <v-list style="height: 100%; overflow-y: scroll;">
                    <v-list-item color="primary" v-for="(project, index) in projects" :key="index">
                      <v-checkbox v-model="project.IsSelected" :label="`${project.Name} (${project.ClientName})`"></v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row><v-checkbox v-model="editPassword" label="Reset Password"></v-checkbox></v-row>
              <v-row><v-btn v-if="editPassword" class="mb-1" variant="outlined" color="primary" @click="editPasswordGenerator()"> <v-icon>mdi-refresh</v-icon> Auto Generate</v-btn></v-row>
              <v-row><v-text-field v-if="editPassword" label="New Password" variant="outlined" v-model="editUser.NewPassword"
                :append-inner-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append-inner="() => (hidePassword = !hidePassword)"
    :type="hidePassword ? 'password' : 'text'" ></v-text-field></v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancelEdit()">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveEdit()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          Do you really want to disable the user <b>{{ deleteUser.Username }}</b> ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancelDelete()">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="savelDelete()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dashboardDialog" max-width="800px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          User <b>{{ dashboardUser.Username }}</b>
        </v-card-title>
        <UserDashBoard :UserId="dashboardUser.Id"></UserDashBoard>
      </v-card>
    </v-dialog>
    <v-dialog v-model="entriesDialog" persistent max-width="1200px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          User <b>{{ entriesUser.Username }}</b>
        </v-card-title>
        <v-data-table :headers="entriesHeaders" :items="entries">
          <!--eslint-disable-next-line-->
          <template v-slot:item.IsEnabled="{ value }">
            <v-chip :color="value == true ? 'green' : 'red'">
              {{ value == true ? 'Accessible' : "Blocked" }}
            </v-chip>
          </template>
          <!--eslint-disable-next-line-->
          <template v-slot:item.actions="{ item }">
            <v-btn icon variant="" size="small" @click="toggleLockEntry(item)">
              <v-icon>{{`${item.IsEnabled == true ? 'mdi-lock' : 'mdi-lock-open-variant'}`}}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="tonal" @click="entriesDialog = false;">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="add" persistent max-width="800px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Add a new user
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row> <v-text-field v-model="newUser.Username" label="Username" variant="outlined"></v-text-field></v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-checkbox v-model="newUser.IsAdmin" label="Is Admin?"></v-checkbox>
              <v-checkbox v-model="newUser.IsTimeTracking" label="Is Time Tracking?"></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row><v-btn class="mb-1" variant="outlined" color="primary" @click="addPasswordGenerator()"> <v-icon>mdi-refresh</v-icon> Auto Generate</v-btn></v-row>
              <v-row><v-text-field label="Password" variant="outlined" v-model="newUser.NewPassword"
                :append-inner-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append-inner="() => (hidePassword = !hidePassword)"
    :type="hidePassword ? 'password' : 'text'"
                ></v-text-field></v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="cancelAdd()">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveAdd()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue';
// Components
import AuthService from '../services/AuthService.js';
import UserService from '../services/UserService.js';
import ProjectService from '../services/ProjectService.js';
import { globalContainer } from '../store';
import router from "../router"
import UserDashBoard from "../components/UserDashBoard.vue";
import EntryService from '../services/EntryService.js';

export default defineComponent({
  name: 'UsersView',

  components: {
    UserDashBoard
  },
  data: () => ({
    search: '',
    edit: false,
    deleteDialog: false,
    add: false,
    users: [],
    editUser: {},
    deleteUser: {},
    newUser: {},
    headers: [
      { title: 'Username', value: 'Username' },
      { title: 'Admin', value: 'IsAdmin' },
      { title: 'Time Tracking', value: 'IsTimeTracking' },
      { title: 'Actions', value: 'actions', sortable: false },
    ],
    editPassword: false,
    hidePassword: false,
    error: {text: "", status: false},
    projects: [ ],
    oldProjects: [],
    dashboardDialog: false,
    dashboardUser: undefined,
    entries: [],
    entriesUser: undefined,
    entriesDialog: false,
    entriesHeaders:[
      { title: 'Date', key: "date", value: item => `${new Date(item.Date).getDate()}/${new Date(item.Date).getMonth()+1}/${new Date(item.Date).getFullYear()}`},
      { title: 'Client',  value: "ClientName"},
      { title: 'Project',  value: "ProjectName"},
      { title: 'Activity',  value: "ActivityName"},
      { title: 'Status', key: "IsEnabled", value: "IsEnabled"},
      { title: 'Description', key: "desc", value: "Description"},
      { title: 'Hours',  key: "hours", value: item => item.Time.substring(0, 5)},
      { title: 'Actions', value: 'actions', sortable: false },
    ]
  }),
  methods: {
    loginFailed() {
      this.$cookies.remove('username');
      this.$cookies.remove('password');
      this.$cookies.remove('current_user');
      router.push("/login");
    },
    showUserDashboard(item){
      this.dashboardUser = item;
      this.dashboardDialog = true;
    },
    showEntriesDialog(item){
      this.entriesUser = item;
      this.entriesDialog = true;
      UserService.getEntries(this.entriesUser.Id).then((result) => {
        this.entries = result;
      })
    },
    toggleLockEntry(item){
      console.log(item)
      EntryService.adminEditEntry(item.Id, item.IdActivity, item.Time, item.Description, new Date(item.Date), !item.IsEnabled, this.entriesUser.Id).then(()=> {
        UserService.getEntries(this.entriesUser.Id).then((result) => {
          this.entries = result;
        })
      })
    },
    editUserDialog(item){
      this.hidePassword = true;
      this.editUser = JSON.parse(JSON.stringify(item));
      if (this.editUser.IsAdmin != true && this.editUser.IsAdmin != false){
        this.editUser.IsAdmin = this.editUser.IsAdmin == "Yes" ? true : false;
      }
      if(this.editUser.IsTimeTracking != true && this.editUser.IsTimeTracking != false){
        this.editUser.IsTimeTracking = this.editUser.IsTimeTracking == "Yes" ? true : false;
      }
      ProjectService.getProjectsByUser(this.editUser.Id).then((result) => {
        this.projects = result;
        this.oldProjects = JSON.parse(JSON.stringify(result));
      });
      this.edit = true;
    },
    deleteUserDialog(item){
      this.deleteUser = JSON.parse(JSON.stringify(item));
      this.deleteDialog = true;
    },
    cancelDelete(){
      this.deleteDialog = false;
    },
    savelDelete(){
      UserService.disableUser(this.deleteUser.Id).then(() => {
        UserService.getUsers().then((result) => {
          this.users = result;
          this.deleteDialog = false;
        })
      })
    },
    addUserDialog(){
      this.hidePassword = true;
      this.newUser = {Username: "", NewPassword: "", IsAdmin: false, IsTimeTracking: false};
      this.add = true;
    },
    cancelEdit(){
      this.edit = false;
      this.editPassword = false;
    },
    saveEdit(){
      if (this.editPassword){
        UserService.editPassword(this.editUser.Id, this.editUser.NewPassword, this.editUser.NewPassword).then(() => {
          UserService.editUserDetails(this.editUser.IsTimeTracking, this.editUser.IsAdmin, this.editUser.Id).then(() => {
            this.projects.forEach((project) => {
              if (project.IsSelected != this.oldProjects.find((pr) => pr.Id == project.Id).IsSelected){
                if(project.IsSelected){
                  ProjectService.addUserToProject(project.Id, this.editUser.Id);
                }
                else{
                  ProjectService.removeUserFromProject(project.Id, this.editUser.Id);
                }
              }
            })
            UserService.getUsers().then((result) => {
              this.users = result;
              this.edit = false;
            })
          })
        })
      }
      else {
        UserService.editUserDetails(this.editUser.IsTimeTracking, this.editUser.IsAdmin, this.editUser.Id).then(() => {
          this.projects.forEach((project) => {
            if (project.IsSelected != this.oldProjects.find((pr) => pr.Id == project.Id).IsSelected){
              if(project.IsSelected){
                ProjectService.addUserToProject(project.Id, this.editUser.Id);
              }
              else{
                ProjectService.removeUserFromProject(project.Id, this.editUser.Id);
              }
            }
          })
          UserService.getUsers().then((result) => {
            this.users = result;
            this.edit = false;
          })
        })
      }
    },
    generatePassword(){
      let length = 25;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}:\"<>?";
      let password = "";
      for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          password += charset[randomIndex];
      }
      return password;
    },
    editPasswordGenerator(){
      this.editUser.NewPassword = this.generatePassword();
    },
    addPasswordGenerator(){
      this.newUser.NewPassword = this.generatePassword();
    },
    cancelAdd(){
      this.add = false;
    },
    saveAdd(){
      UserService.addUser(this.newUser.Username, this.newUser.NewPassword, this.newUser.IsAdmin, this.newUser.IsTimeTracking).then(() => {
        UserService.getUsers().then((result) => {
          this.users = result;
          this.add = false;
        })
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    }
  },  
  beforeMount() {
    AuthService.getUser()
    .then((data) => {
      globalContainer.User = data.Login;
      if(globalContainer.User.IsAdmin == false){
        router.push("/");
      }
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          router.go();
        })
        .catch(() => {
          this.loginFailed();
        })
      }
      else{
        globalContainer.User = {};
        this.loginFailed();
      }
    });
  },
  computed: {
    getItems(){
      return this.users.map((user) => {
        if (user.IsAdmin == true || user.IsAdmin == false){
          user.IsAdmin = user.IsAdmin == 1 ? "Yes" : "No";
        }
        if (user.IsTimeTracking == true || user.IsTimeTracking == false){
          user.IsTimeTracking = user.IsTimeTracking == 1 ? "Yes" : "No";
        }
        return user;
      })
    }
  },
  mounted(){
    UserService.getUsers().then((result) => {
      this.users = result;
    });
  }
});
</script>

<style>
.search-field-with-icon .v-icon {
  background-color: #e2ecf7; /* Light grey background */
  border-radius: 50%; /* Circular shape */
  padding: 25px; /* Smaller touch area */
  margin-right: -12px; /* Adjust based on your layout */
  cursor: pointer; /* Cursor changes to pointer on hover */
  color: #1f6cc1;
}
/* Adjust icon color on hover */
.search-field-with-icon .v-icon:hover {
  background-color: #bfd9f5; /* Darker grey on hover */
}
</style>