<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <h4 class="ml-1 mb-2">Clients</h4>
            <v-text-field v-model="searchClient"  label="Search..." variant="outlined" append-icon="mdi-plus" @click:append="openaddClient()" class="search-field-with-icon" @input="reloadProject()"></v-text-field>
          </v-card-title>
          <v-list class="scrollable-list">
            <v-list-item v-for="(client, index) in filteredClients" :active="index === selectedClient" @click="selectClient(index)" :key="index" color="primary">
              <v-list-item-title>{{ client.Name }}</v-list-item-title>
              <template v-slot:append>
                <v-tooltip location="top" text="Delete">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props"  size="small" icon @click.stop="opendeleteClient(client)" variant="text">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip location="top" text="Delete">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" icon @click.stop="openeditClient(client)" variant="text">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card>
          <v-card-title>
            <h4 class="ml-1 mb-2">Projects of {{ filteredClients.length > 0 ? filteredClients[selectedClient].Name : "" }}</h4>
            <v-text-field  :disabled="filteredClients.length == 0"  v-model="searchProject"  label="Search..." variant="outlined" append-icon="mdi-plus" @click:append="openaddProject()" class="search-field-with-icon"></v-text-field>
          </v-card-title>
          <v-list class="scrollable-list">
            <v-list-item v-for="(project, index) in filteredProjects" :key="index" :active="index === selectedProject" @click="selectProject(index)" color="primary">
              <v-list-item-title>{{ project.Name }}</v-list-item-title>
              <template v-slot:append>
                <v-tooltip location="top" text="Delete">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" icon @click.stop="opendeleteProject(project)" variant="text">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip location="top" text="Edit">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" icon @click.stop="openeditProject(project)" variant="text">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip location="top" text="Show Project Status">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" icon @click.stop="openProjectStatus(project)" variant="text">
                      <v-icon>mdi-chart-line</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card>
          <v-card-title>
            <h4 class="ml-1 mb-2">Activities of {{ filteredProjects.length > 0 ? filteredProjects[selectedProject].Name : "" }}</h4>
            <v-text-field  :disabled="filteredProjects.length == 0"  v-model="searchActivity"  label="Search..." variant="outlined" append-icon="mdi-plus" @click:append="openAddActivity()" class="search-field-with-icon"></v-text-field>
          </v-card-title>
          <v-list class="scrollable-list">
            <v-list-item v-for="(activity, index) in activities" :key="index">
              <v-list-item-title>{{ activity.Name }}</v-list-item-title>
              <template v-slot:append>
                <v-tooltip location="top" text="Delete">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" icon @click.stop="openDeleteActivity(activity)" variant="text">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip location="top" text="Edit">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" icon @click.stop="openEditActivity(activity)" variant="text">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="projectStatusDialog" max-width="90%" height="90%" transition="dialog-bottom-transition">
      <ProjectDashBoard :ProjectId="statusProject.Id" :ProjectName="statusProject.Name"></ProjectDashBoard>
    </v-dialog>

    <v-dialog v-model="addActivityDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Add a new activity
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col><v-row><v-text-field v-model="newActivity.Name" label="Name" variant="outlined"></v-text-field></v-row></v-col>
          </v-row>
          <v-row><v-text-field @input="newActivity.TimeBudget = validateNumber(newActivity.TimeBudget);" v-model="newActivity.TimeBudget" label="Time Budget (Hours)" variant="outlined"></v-text-field></v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addActivityDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveAddActivity()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editActivityDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Editing activity <b>{{editActivity.Name}}</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col><v-row><v-text-field v-model="editActivity.Name" label="Name" variant="outlined"></v-text-field></v-row></v-col>
          </v-row>
          <v-row><v-text-field @input="editActivity.TimeBudget = validateNumber(editActivity.TimeBudget);" v-model="editActivity.TimeBudget" label="Time Budget (Hours)" variant="outlined"></v-text-field></v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="editActivityDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveEditActivity()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteActivityDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          Do you really want to delete the activity <b>{{ deleteActivity.Name }}</b> ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteActivityDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveDeleteActivity()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addClientDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Add a new client
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col><v-row><v-text-field v-model="newClient.Name" label="Name" variant="outlined"></v-text-field></v-row></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addClientDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveAddClient()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editClientDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Editing client <b>{{editClient.FixedName}}</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col><v-row><v-text-field v-model="editClient.Name" label="Name" variant="outlined"></v-text-field></v-row></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="editClientDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveEditClient()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteClientDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          Do you really want to delete the client <b>{{ deleteClient.Name }}</b> ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteClientDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveDeleteClient()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addProjectDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Add a new project
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row><v-text-field v-model="newProject.Name" label="Name" variant="outlined"></v-text-field></v-row>
              <v-row><v-textarea v-model="newProject.Description" label="Description" variant="outlined"></v-textarea></v-row>
              <v-row><v-text-field @input="newProject.HourlyRate = validateNumber(newProject.HourlyRate);" v-model="newProject.HourlyRate" label="Hourly Rate (€ Euro)" variant="outlined"></v-text-field></v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProjectDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveAddProject()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editProjectDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-alert color="error" icon="$error" title="Error" :text="error.text" v-if="error.status" ></v-alert>
      <v-card>
        <v-card-title>
          Editing project <b>{{ editProject.FixedName }}</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row><v-text-field v-model="editProject.Name" label="Name" variant="outlined"></v-text-field></v-row>
              <v-row><v-textarea v-model="editProject.Description" label="Description" variant="outlined"></v-textarea></v-row>
              <v-row><v-text-field @input="editProject.HourlyRate = validateNumber(editProject.HourlyRate);" v-model="editProject.HourlyRate" label="Hourly Rate (€ Euro)" variant="outlined"></v-text-field></v-row>
              <v-row>
                <v-col>
                  <v-row >
                    <v-card color="primary" width="100%" height="40vh">
                      <v-card-title>Assigned Users</v-card-title>  
                      <v-list style="height: 100%; overflow-y: scroll;">
                        <v-list-item color="primary" v-for="(user, index) in users" :key="index">
                          <v-checkbox v-model="user.IsSelected" :label="`${user.Username}`"></v-checkbox>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="editProjectDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveEditProject()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteProjectDialog" persistent max-width="600px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          Do you really want to delete the project <b>{{ deleteProject.Name }}</b> ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteProjectDialog = false;">Cancel</v-btn>
          <v-btn color="primary" variant="tonal" @click="saveDeleteProject()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

// Services
import AuthService from '../services/AuthService.js';
import ClientService from '../services/ClientService.js';
import ProjectService from '../services/ProjectService.js';
import ActivityService from '../services/ActivityService.js';
import ProjectDashBoard from '../components/ProjectDashBoard.vue';
import { globalContainer } from '../store';
import router from "../router"

export default defineComponent({
  name: 'ClientProjectView',
  components:{
    ProjectDashBoard
  },
  data: () => ({
    users: [],
    oldUsers: [],
    clients: [],
    projects: [],
    activities: [],
    selectedClient: 0,
    selectedProject: 0,
    searchClient: "",
    searchProject: "",
    searchActivity: "",
    addClientDialog: false,
    editClientDialog: false,
    deleteClientDialog: false,
    addProjectDialog: false,
    editProjectDialog: false,
    deleteProjectDialog: false,
    projectStatusDialog: false,
    addActivityDialog: false,
    deleteActivityDialog: false,
    editActivityDialog: false,
    newClient: {},
    newProject: {},
    newActivity: {},
    editActivity: {},
    editClient: {},
    editProject: {},
    deleteClient: {},
    deleteProject: {},
    deleteActivity: {},
    statusProject: {},
    error: {text: "", status: false},
  }),
  methods: {
    loginFailed() {
      this.$cookies.remove('username');
      this.$cookies.remove('password');
      this.$cookies.remove('current_user');
      router.push("/login");
    },
    openAddActivity(){
      this.newActivity = JSON.parse(JSON.stringify({Name: "", TimeBudget: 0.0}));
      this.addActivityDialog = true;
    },
    saveAddActivity(){
      ActivityService.addActivity(this.newActivity.Name, this.filteredProjects[this.selectedProject].Id, this.newActivity.TimeBudget).then(() => {
        this.addActivityDialog = false;
        this.reloadActivities();
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    openDeleteActivity(activity){
      this.deleteActivity = JSON.parse(JSON.stringify(activity));
      this.deleteActivityDialog = true;
    },
    saveDeleteActivity(){
      ActivityService.deleteActivity(this.deleteActivity.Id).then(() => {
        this.deleteActivityDialog = false;
        this.reloadActivities();
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    openEditActivity(activity){
      this.editActivity = JSON.parse(JSON.stringify(activity));
      this.editActivityDialog = true;
    },
    saveEditActivity(){
      ActivityService.editActivity(this.editActivity.Name, this.editActivity.IdProject, this.editActivity.Id, this.editActivity.TimeBudget).then(() => {
        this.editActivityDialog = false;
        this.reloadActivities();
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    openeditClient(client){
      this.editClient = JSON.parse(JSON.stringify(client));
      this.editClient.FixedName = JSON.parse(JSON.stringify(this.editClient.Name));
      this.editClientDialog = true;
    },
    opendeleteClient(client){
      this.deleteClient = JSON.parse(JSON.stringify(client));
      this.deleteClientDialog = true;
    },
    openeditProject(project){
      ProjectService.getUsersByProject(project.Id).then((result) => {
        result = result.map((el) => {
          el.IsSelected = Boolean(el.IsSelected)
          return el;
        })
        this.users = result;
        this.oldUsers = JSON.parse(JSON.stringify(result));
      });
      this.editProject = JSON.parse(JSON.stringify(project));
      this.editProject.FixedName = JSON.parse(JSON.stringify(this.editProject.Name));
      this.editProjectDialog = true;
    },
    opendeleteProject(project){
      this.deleteProject = JSON.parse(JSON.stringify(project));
      this.deleteProjectDialog = true;
    },
    openaddClient(){
      this.newClient = JSON.parse(JSON.stringify({Name: ""}));
      this.addClientDialog = true;
    },
    openaddProject(){
      this.newProject = JSON.parse(JSON.stringify({Name: "", Description: "",  HourlyRate: 100.0, IdClient: this.filteredClients[this.selectedClient].Id}))
      this.addProjectDialog = true;
    },
    selectClient(index){
      this.selectedClient = index;
    },
    selectProject(index){
      this.selectedProject = index;
    },
    openProjectStatus(project){
      this.statusProject = project;
      this.projectStatusDialog = true;
    },
    saveAddClient(){
      ClientService.addClient(this.newClient.Name).then(() => {
        ClientService.getClients().then((data) => {
          this.reloadProject();
          this.clients = data;
          this.addClientDialog = false;
        });
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    saveEditClient(){
      ClientService.editClient(this.editClient.Name, this.editClient.Id).then(() => {
        ClientService.getClients().then((data) => {
          this.reloadProject();
          this.clients = data;
          this.editClientDialog = false;
        });
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    saveDeleteClient(){
      ClientService.disableClient(this.deleteClient.Id).then(() => {
        this.selectedClient = 0;
        this.searchClient = "";
        ClientService.getClients().then((data) => {
          this.reloadProject();
          this.clients = data;
          this.deleteClientDialog = false;
        });
      })
    },
    reloadProject(){
      if (this.filteredClients.length == 0){
        this.projects =  [];
        return;
      }
      ProjectService.getProjectsByClient(this.filteredClients[this.selectedClient].Id).then((data) => {
        this.projects = data;
        this.reloadActivities();
      }).catch(() => this.projects = [])
    },
    reloadActivities(){
      if (this.filteredProjects.length == 0){
        this.activities =  [];
        return;
      }
      ActivityService.getActivities(this.filteredProjects[this.selectedProject].Id).then((data) => {
        this.activities = data;
      }).catch(() => this.activities = [])
    },
    saveAddProject(){
      ProjectService.addProject(this.newProject.IdClient, this.newProject.Name, this.newProject.Description,  this.newProject.HourlyRate).then(() =>{
        this.reloadProject();
        this.addProjectDialog = false;
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    validateNumber(num){
      let formatted = num.replace(/[^0-9.]/g, '');
      return Number(formatted);
    },
    saveEditProject(){
      ProjectService.editProject(this.editProject.Id, this.editProject.Name, this.editProject.Description, this.editProject.HourlyRate).then(() =>{
        this.users.forEach((user) => {
          if (user.IsSelected != this.oldUsers.find((us) => us.Id == user.Id).IsSelected){
            if(user.IsSelected){
              ProjectService.addUserToProject(this.editProject.Id, user.Id);
            }
            else{
              ProjectService.removeUserFromProject(this.editProject.Id, user.Id);
            }
          }
        })
        this.reloadProject();
        this.editProjectDialog = false;
      }).catch((error) => {
        this.error.status = true;
        this.error.text = error.response.data;
        setTimeout(() => {
          this.error.status = false;
        }, (5000));
      });
    },
    saveDeleteProject(){
      ProjectService.deleteProject(this.deleteProject.Id).then(() =>{
        this.selectedProject = 0;
        this.searchProject = "";
        this.reloadProject();
        this.deleteProjectDialog = false;
      });
    }
  },
  beforeMount() {
    AuthService.getUser()
    .then((data) => {
      globalContainer.User = data.Login;
      if(globalContainer.User.IsAdmin == false){
        router.push("/");
      }
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          router.go();
        })
        .catch(() => {
          this.loginFailed();
        })
      }
      else{
        globalContainer.User = {};
        this.loginFailed();
      }
    });
  },
  mounted(){
    ClientService.getClients().then((data) => {
      this.clients = data;
      this.reloadProject();
    }).catch(() => {});
  },
  computed: {
    filteredClients(){
      return this.clients.filter((c) => c.Name.toLowerCase().includes(this.searchClient.toLowerCase()));
    },
    filteredProjects(){
      return this.projects.filter((c) => c.Name.toLowerCase().includes(this.searchProject.toLowerCase()));
    }
  },
  watch: {
    selectedClient(newValue) {
      ProjectService.getProjectsByClient(this.filteredClients[newValue].Id).then((data) => {
        this.projects = data;
        if(data.length > 0)
          this.selectedProject = 0;
        this.reloadActivities();
      }).catch(() => this.projects = [])
    },
    selectedProject(newValue) {
      ActivityService.getActivities(this.filteredProjects[newValue].Id).then((data) => {
        this.activities = data;
      }).catch(() => this.activities = [])
    },
  },
});
</script>

<style scoped>
.scrollable-list {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
