import axios from 'axios';

class EntryService {

    static getEntries(date) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const offset = date.getTimezoneOffset();
                date = new Date(date.getTime() - (offset*60*1000));
                date = date.toISOString().split('T')[0];
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/entry?Date=${date}`, { withCredentials: true, });
                let data = res.data;
                data.map((el) => {
                    el.Time = el.Time.substring(0,5);
                    return el;
                })
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static editEntry(id, idActivity, time, description, date, timerStart=undefined) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const offset = date.getTimezoneOffset();
                date = new Date(date.getTime() - (offset*60*1000));
                date = date.toISOString().split('T')[0];
                const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/api/entry`, { Id: id, IdActivity: idActivity, Time: time, Description: description, Date: date, TimerStart: timerStart }, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static adminEditEntry(id, idActivity, time, description, date, isEnabled, userId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const offset = date.getTimezoneOffset();
                date = new Date(date.getTime() - (offset*60*1000));
                date = date.toISOString().split('T')[0];
                const res = await axios.patch(`${process.env.VUE_APP_BASE_URL}/api/entry`, { IdUser: userId, Id: id, IdActivity: idActivity, Time: time, Description: description, Date: date, IsEnabled: isEnabled }, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static addEntry(idActivity, time, description, date) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const offset = date.getTimezoneOffset();
                date = new Date(date.getTime() - (offset*60*1000));
                date = date.toISOString().split('T')[0];
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/entry`, { IdActivity: idActivity, Time: time, Description: description, Date: date }, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    static deleteEntry(id) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/entry?Id=${id}`, { withCredentials: true, });
                const data = res.data;
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default EntryService;
