<template>
    <v-card>
        <v-card-title class="mb-0"> 
            Work Hours
            <v-row class="mt-1 mb-0">
                <v-col><v-select label="Time Span" variant="outlined" v-model="selectedTimeSpan" :items="timeSpans"></v-select></v-col>
            </v-row>
        </v-card-title>
          <v-card height="50vh" variant="text" >
            <ApexCharts type="bar" height="100%" :options="chartOptions" :series="series" :key="chartOptions"></ApexCharts>
          </v-card>
    </v-card>
</template>
  
<script>
  import VueApexCharts from "vue3-apexcharts";
  import UserService from '../services/UserService.js';

  export default {
    props: {
      UserId: {
        type: Number,
        default: undefined
      },
    },
    components: {
      ApexCharts: VueApexCharts
    },
    data() {
      return {
        timeSpans: ["This Week", "Last Week", "This Month", "Last Month", "This Year", "Last Year"],
        selectedTimeSpan: "This Week",
        workHours: [],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }
            },
          },
          xaxis: {
            type: 'string',
            categories: [],
          },
          legend: {
            position: 'right',
            offsetY: 40
          },
          fill: {
            opacity: 1
          }
        },
        series: [{
          name: 'WorkHours',
          data: []
        }],
      };
    },
    methods: {
      formatTime(event) {
        this.localEntry.Time = this.applyTimeFormat(event.target.value);
      },
      reloadGraph(){
        UserService.getWorkingHours(this.selectedTimeSpan, this.UserId).then((res) => {
          let labels = [];
          res.forEach(el => {
            if (el.label == undefined){
              el.label = el.Date.split("-")[2];
            }
            labels.push(el.label)
          });
          this.chartOptions.xaxis.categories = labels;
          this.chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
          this.series[0].data = [];
          res.forEach((el) => {
            this.series[0].data.push((Number(el.value.substring(0, 2)) + Number(el.value.substring(3, 5)) / 60).toFixed(2));
          })
        }).catch(() => {});
      }
    },
    mounted(){
      this.reloadGraph();
    },
    watch: {
      selectedTimeSpan() {
        this.reloadGraph();
      },
      selectedType() {
        this.reloadGraph();
      },
    },
  };
</script>
